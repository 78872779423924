"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setActivationKey = exports.setSelectedTenant = exports.tenantsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const tenants_1 = require("@services/tenants");
const constants_1 = require("@appConstants/constants");
const defaultPermissions_1 = require("@appConstants/defaultPermissions");
const initialState = {
    tenants: undefined,
    currentTenantSettings: undefined,
    selectedTenant: undefined,
    getTenantsStatus: constants_1.REQUEST_STATUS.IDLE,
    createTenantStatus: constants_1.REQUEST_STATUS.IDLE,
    getTenantSettingStatus: constants_1.REQUEST_STATUS.IDLE,
    activationKey: '',
    activationKeyStatus: constants_1.REQUEST_STATUS.IDLE,
    permissions: {},
    archiveTenants: undefined,
    getArchiveTenantsStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.tenantsSlice = (0, toolkit_1.createSlice)({
    name: 'tenants',
    initialState,
    reducers: {
        setSelectedTenant: (state, action) => {
            state.selectedTenant = action.payload;
        },
        setActivationKey: (state) => {
            state.activationKey = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(tenants_1.fetchTenants.pending, (state) => {
            state.tenants = [];
            state.selectedTenant = undefined;
            state.getTenantsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchTenants.fulfilled, (state, action) => {
            if (action.payload) {
                state.tenants = action.payload;
                state.getTenantsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the tenants', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.fetchTenants.rejected, (state) => {
            state.tenants = [];
            state.getTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the tenants', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchArchiveTenants.pending, (state) => {
            state.archiveTenants = [];
            state.selectedTenant = undefined;
            state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchArchiveTenants.fulfilled, (state, action) => {
            if (action.payload) {
                state.archiveTenants = action.payload;
                state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.fetchArchiveTenants.rejected, (state) => {
            state.archiveTenants = [];
            state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the archive tenants', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.archiveTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.archiveTenant.fulfilled, (state, action) => {
            if (action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Tenant archived successfully! ', {
                    type: 'success',
                    autoClose: 4000,
                });
            }
            else {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.archiveTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Failed to archive the tenant ', {
                type: 'error',
                autoClose: 4000,
            });
        });
        builder
            .addCase(tenants_1.restoreTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.restoreTenant.fulfilled, (state, action) => {
            if (action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Tenant restored successfully! ', {
                    type: 'success',
                    autoClose: 4000,
                });
            }
            else {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.restoreTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Failed to restore the tenant ', {
                type: 'error',
                autoClose: 4000,
            });
        });
        builder
            .addCase(tenants_1.createTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.createTenant.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error creating a new tenant', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The tenant has been created successfully', {
                type: 'success',
            });
        })
            .addCase(tenants_1.createTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error creating a new tenant', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenant.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the tenant', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The tenant has been updated successfully', {
                type: 'success',
            });
        })
            .addCase(tenants_1.updateTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the tenant', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchActivationKey.pending, (state) => {
            state.activationKey = '';
            state.activationKeyStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchActivationKey.fulfilled, (state, action) => {
            if (!action.payload) {
                state.activationKey = '';
                state.activationKeyStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error getting an activation key', {
                    type: 'error',
                });
                return;
            }
            state.activationKey = action.payload;
            state.activationKeyStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(tenants_1.fetchActivationKey.rejected, (state) => {
            state.activationKey = '';
            state.activationKeyStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error getting an activation key', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchTenantSettings.pending, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchTenantSettings.fulfilled, (state, action) => {
            var _a;
            if (action.payload) {
                state.currentTenantSettings = action.payload.tenant;
                state.permissions = (_a = action.payload.permissions) !== null && _a !== void 0 ? _a : defaultPermissions_1.defaultPermissionsForPayload;
                state.permissions['Home'] = state.permissions['Appointments'];
                delete state.permissions['Appointments'];
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the tenant settings', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.fetchTenantSettings.rejected, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the tenant settings', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenantSettings.pending, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenantSettings.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentTenantSettings = action.payload;
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Tenant setting has been updated successfully', {
                    type: 'success',
                });
            }
            else {
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the tenant settings', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.updateTenantSettings.rejected, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the tenant settings', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenantProfile.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenantProfile.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the tenant', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The tenant has been updated successfully', {
                type: 'success',
            });
            state.currentTenantSettings = action.payload;
        })
            .addCase(tenants_1.updateTenantProfile.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the tenant', {
                type: 'error',
            });
        });
    },
});
_a = exports.tenantsSlice.actions, exports.setSelectedTenant = _a.setSelectedTenant, exports.setActivationKey = _a.setActivationKey;
exports.default = exports.tenantsSlice.reducer;
