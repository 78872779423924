"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMerchantConfig = exports.fetchMerchantConfig = exports.saveMerchantConfig = void 0;
const initialize_instance_1 = require("@apis/initialize.instance");
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const react_toastify_1 = require("react-toastify");
exports.saveMerchantConfig = (0, toolkit_1.createAsyncThunk)('merchant/saveMerchantConfig', (formData, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { tenantId, token } = storeState.currentUserReducer;
    if (!tenantId || !token) {
        return Promise.reject('Missing tenant ID or token');
    }
    const response = yield initialize_instance_1.merchantInstance.post(`/config/${tenantId}`, formData, (0, axiosHelper_1.addTokenToConfig)({}, token));
    return response.data;
}));
exports.fetchMerchantConfig = (0, toolkit_1.createAsyncThunk)('merchant/fetchMerchantConfig', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { tenantId, token } = storeState.currentUserReducer;
    if (!tenantId || !token) {
        return Promise.reject('Missing tenant ID or token');
    }
    const response = yield initialize_instance_1.merchantInstance.get(`/config/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
    return response.data;
}));
exports.updateMerchantConfig = (0, toolkit_1.createAsyncThunk)('merchant/updateMerchantConfig', (formData, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { tenantId, token } = storeState.currentUserReducer;
    if (!tenantId || !token) {
        return Promise.reject('Missing tenant ID or token');
    }
    const response = yield initialize_instance_1.merchantInstance.patch(`/config/${tenantId}`, formData, (0, axiosHelper_1.addTokenToConfig)({}, token));
    (0, react_toastify_1.toast)('Merchant Config updated Successfully', {
        type: 'success',
        autoClose: 5000,
    });
    return response.data;
}));
