"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleMessageAttention = exports.addMessagesExtra = exports.addIncomingMessage = exports.setCurrentConversationNew = exports.setCurrentConversation = exports.conversationsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const conversations_1 = require("@services/conversations");
const conversationUtil_1 = require("@utils/conversationUtil");
const initialState = {
    conversations: [],
    loaded: 0,
    total: 0,
    getConversationsStatus: constants_1.REQUEST_STATUS.IDLE,
    getMessagesStatus: constants_1.REQUEST_STATUS.IDLE,
    isSending: constants_1.REQUEST_STATUS.IDLE,
    isMarking: constants_1.REQUEST_STATUS.IDLE,
    currentConversation: undefined,
    scrollToConversation: -1,
    extrainfo: undefined,
};
exports.conversationsSlice = (0, toolkit_1.createSlice)({
    name: 'conversations',
    initialState,
    reducers: {
        setCurrentConversation: (state, action) => {
            const convo = Object.assign({}, action.payload);
            convo.hasMore = true;
            state.currentConversation = action.payload;
            state.scrollToConversation = -1;
        },
        setCurrentConversationNew: (state, action) => {
            state.currentConversation = action.payload;
            state.scrollToConversation = -1;
        },
        addIncomingMessage: (state, action) => {
            if (!action.payload || !Array.isArray(action.payload) || action.payload.length < 1) {
                return;
            }
            const newConversations = (0, conversationUtil_1.addMessagesToConversations)([...state.conversations], { totalMessages: -1, messages: action.payload });
            state.conversations = newConversations;
            if (state.currentConversation) {
                const convo = newConversations.find((conv) => { var _a; return conv.sessionNumber === ((_a = state.currentConversation) === null || _a === void 0 ? void 0 : _a.sessionNumber); });
                state.currentConversation = convo;
            }
        },
        addMessagesExtra: (state, action) => {
            state.extrainfo = action.payload;
        },
        handleMessageAttention: (state, action) => {
            let userConversations = state.conversations.map((conv) => {
                var _a;
                if (conv.sessionNumber === ((_a = state.currentConversation) === null || _a === void 0 ? void 0 : _a.sessionNumber)) {
                    conv.needsAttention = action.payload;
                    conv.messages[0].handled = !action.payload;
                }
                return conv;
            });
            userConversations = (0, conversationUtil_1.orderConversations)(userConversations);
            const scrollTo = userConversations.findIndex((conv) => { var _a; return (conv === null || conv === void 0 ? void 0 : conv.sessionNumber) === ((_a = state.currentConversation) === null || _a === void 0 ? void 0 : _a.sessionNumber); });
            if (scrollTo > -1) {
                state.scrollToConversation = scrollTo;
                state.currentConversation = userConversations[scrollTo];
            }
            state.conversations = userConversations;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(conversations_1.fetchConversations.pending, (state) => {
            state.getConversationsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(conversations_1.fetchConversations.fulfilled, (state, action) => {
            if (!action.payload || !Array.isArray(action.payload.conversations)) {
                state.getConversationsStatus = constants_1.REQUEST_STATUS.FAILED;
                if (state.loaded === 0) {
                    (0, react_toastify_1.toast)('Error loading the conversation', {
                        type: 'error',
                        autoClose: 5000,
                    });
                }
                return;
            }
            state.getConversationsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.total = action.payload.total;
            state.loaded = action.payload.loaded;
            if (!Array.isArray(state.conversations)) {
                state.conversations = [];
            }
            const convos = (0, conversationUtil_1.removeDuplicates)([...state.conversations, ...action.payload.conversations]);
            const newConversations = (0, conversationUtil_1.orderConversations)(convos);
            state.conversations = newConversations;
        })
            .addCase(conversations_1.fetchConversations.rejected, (state, action) => {
            state.getConversationsStatus = constants_1.REQUEST_STATUS.FAILED;
            if (state.loaded === 0) {
                (0, react_toastify_1.toast)('Error loading the conversation', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        });
        builder
            .addCase(conversations_1.fetchMoreMessages.pending, (state) => {
            state.getMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(conversations_1.fetchMoreMessages.fulfilled, (state, action) => {
            if (!action.payload || Object.keys(action.payload).length < 1 || !Array.isArray(action.payload.messages)) {
                state.getMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading more messages', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.getMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            const newConversations = (0, conversationUtil_1.addMessagesToConversations)([...state.conversations], action.payload);
            state.conversations = newConversations;
            if (state.currentConversation) {
                const convo = newConversations.find((conv) => { var _a; return conv.sessionNumber === ((_a = state.currentConversation) === null || _a === void 0 ? void 0 : _a.sessionNumber); });
                state.currentConversation = convo;
            }
        })
            .addCase(conversations_1.fetchMoreMessages.rejected, (state, action) => {
            state.getMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading more messages', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(conversations_1.sendMessageToPatient.pending, (state) => {
            state.isSending = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(conversations_1.sendMessageToPatient.fulfilled, (state, action) => {
            if (action.payload && action.payload.send) {
                state.isSending = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.isSending = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error sending message', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(conversations_1.sendMessageToPatient.rejected, (state, action) => {
            state.isSending = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error sending message', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(conversations_1.makrConversationHandled.pending, (state) => {
            state.isMarking = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(conversations_1.makrConversationHandled.fulfilled, (state, action) => {
            if (action.payload && action.payload) {
                state.isMarking = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.isMarking = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error marking the conversation', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(conversations_1.makrConversationHandled.rejected, (state, action) => {
            state.isMarking = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error marking the conversation', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.conversationsSlice.actions, exports.setCurrentConversation = _a.setCurrentConversation, exports.setCurrentConversationNew = _a.setCurrentConversationNew, exports.addIncomingMessage = _a.addIncomingMessage, exports.addMessagesExtra = _a.addMessagesExtra, exports.handleMessageAttention = _a.handleMessageAttention;
exports.default = exports.conversationsSlice.reducer;
