"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActivationKey = exports.updateTenantProfile = exports.updateTenant = exports.createTenant = exports.archiveTenant = exports.restoreTenant = exports.fetchArchiveTenants = exports.fetchTenants = exports.updateTenantSettings = exports.fetchTenantSettings = exports.createMerchant = void 0;
/* eslint-disable no-useless-catch */
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const constants_1 = require("@appConstants/constants");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.createMerchant = (0, toolkit_1.createAsyncThunk)('merchant/createMerchant', (formData, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token } = storeState.currentUserReducer;
        if (!tenantId || !token) {
            return Promise.reject('Missing tenant ID or token');
        }
        const response = yield initialize_instance_1.merchantInstance.post(`/${tenantId}`, formData, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchTenantSettings = (0, toolkit_1.createAsyncThunk)('tenants/fetchTenantSettings', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.get(`tenants/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            response = yield initialize_instance_1.tenantInstance.get(`tenants/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenantSettings = (0, toolkit_1.createAsyncThunk)('tenants/updateTenantSettings', (setting, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.patch(`tenants/basic/${tenantId}`, setting, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            response = yield initialize_instance_1.tenantInstance.patch(`tenants/basic/${tenantId}`, setting, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchTenants = (0, toolkit_1.createAsyncThunk)('tenants/fetchTenants', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.get('tenants', (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchArchiveTenants = (0, toolkit_1.createAsyncThunk)('archiveTenants/fetchArchiveTenants', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.get('tenants/archive-tenants', (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.restoreTenant = (0, toolkit_1.createAsyncThunk)('/restore/tenants', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const dispatch = thunkApi.dispatch;
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.patch(`tenants/archive-tenants/restore/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data.result) {
            dispatch((0, exports.fetchArchiveTenants)());
            dispatch((0, exports.fetchTenants)());
        }
        return response.data;
    }
    catch (error) {
        throw error;
    }
}));
exports.archiveTenant = (0, toolkit_1.createAsyncThunk)('archive/tenants', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const dispatch = thunkApi.dispatch;
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.patch(`tenants/archive/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data.result) {
            dispatch((0, exports.fetchTenants)());
            dispatch((0, exports.fetchArchiveTenants)());
        }
        return response.data;
    }
    catch (error) {
        throw error;
    }
}));
exports.createTenant = (0, toolkit_1.createAsyncThunk)('tenants/createTenant', (tenant, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.post('tenants', tenant, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenant = (0, toolkit_1.createAsyncThunk)('tenants/updateTenant', ({ id, tenant, userIds }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const body = {
            tenant,
            userIds,
        };
        const response = yield initialize_instance_1.adminInstance.patch(`tenants/${id}`, body, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchTenants)());
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateTenantProfile = (0, toolkit_1.createAsyncThunk)('tenants/updateTenentProfile', ({ id, tenant }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('nocurrentUser');
        }
        const userIds = [];
        const body = {
            tenant,
            userIds,
        };
        const response = yield initialize_instance_1.tenantInstance.patch(`tenants/${id}`, body, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchActivationKey = (0, toolkit_1.createAsyncThunk)('tenants/fetchActivationKey', ({ tenantId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.post(`tenants/new-activation-key/${tenantId}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
