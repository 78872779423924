"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMIN_ROUTES = exports.PROTECTED_ROUTES = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const routes_1 = require("@utils/routes");
const HomePage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/HomePage'))));
const ConversationPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/ConversationPage'))));
const ReminderPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/ReminderPage'))));
const SettingPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/SettingPage'))));
const StatusMapperPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/StatusMapperPage'))));
const UserManagementPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/UserManagementPage'))));
const SetupPaymentPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/SetupPaymentPage'))));
const TenantProfilePage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/ProfilePage'))));
const TenantFormPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/FormPage'))));
const NewTenantPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/NewTenantPage'))));
const TenantsPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/TenantsPage'))));
const AdminStatusMapperPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/StatusMapperPage'))));
const AdminUserManagementPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/UserManagementPage'))));
const PermissionsPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/PermissionPage'))));
const ChangePasswordPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/ChangePassword'))));
const AppointmentPaymentPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/AppointmentPayment'))));
const TreatmentPlanPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/TreatmentPlanPage'))));
const ArchiveTenants = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/ArchiveTenants/index'))));
const ArchivedUsers = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/admin/ArchiveUsers/index'))));
const PaymentPage = (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('@pages/protected/tenant/Payment'))));
// Protected Routes
exports.PROTECTED_ROUTES = [
    {
        id: 1,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.home,
        element: (0, jsx_runtime_1.jsx)(HomePage, {}),
    },
    {
        id: 2,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.conversation,
        element: (0, jsx_runtime_1.jsx)(ConversationPage, {}),
    },
    {
        id: 3,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.reminders,
        element: (0, jsx_runtime_1.jsx)(ReminderPage, {}),
    },
    {
        id: 4,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.settings,
        element: (0, jsx_runtime_1.jsx)(SettingPage, {}),
    },
    {
        id: 5,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.statusMapper,
        element: (0, jsx_runtime_1.jsx)(StatusMapperPage, {}),
    },
    {
        id: 6,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.userManagement,
        element: (0, jsx_runtime_1.jsx)(UserManagementPage, {}),
    },
    {
        id: 7,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.root,
        element: (0, jsx_runtime_1.jsx)(HomePage, {}),
    },
    {
        id: 8,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.verifyStripe,
        element: (0, jsx_runtime_1.jsx)(SetupPaymentPage, {}),
    },
    {
        id: 9,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.tenantProfile,
        element: (0, jsx_runtime_1.jsx)(TenantProfilePage, {}),
    },
    {
        id: 10,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.forms,
        element: (0, jsx_runtime_1.jsx)(TenantFormPage, {}),
    },
    {
        id: 11,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.treatmentPlans,
        element: (0, jsx_runtime_1.jsx)(TreatmentPlanPage, {}),
    },
    {
        id: 12,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.changePassword,
        element: (0, jsx_runtime_1.jsx)(ChangePasswordPage, {}),
    },
    {
        id: 13,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.payment,
        element: (0, jsx_runtime_1.jsx)(PaymentPage, {}),
    },
    {
        id: 14,
        path: routes_1.ROUTES.PROTECTED_ROUTES_NAMES.appointmentPayment,
        element: (0, jsx_runtime_1.jsx)(AppointmentPaymentPage, {}),
    },
];
exports.ADMIN_ROUTES = [
    {
        id: 1,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.tenants,
        element: (0, jsx_runtime_1.jsx)(TenantsPage, {}),
    },
    {
        id: 2,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.newTenant,
        element: (0, jsx_runtime_1.jsx)(NewTenantPage, {}),
    },
    {
        id: 3,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.statusMapper,
        element: (0, jsx_runtime_1.jsx)(AdminStatusMapperPage, {}),
    },
    {
        id: 4,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.userManagement,
        element: (0, jsx_runtime_1.jsx)(AdminUserManagementPage, {}),
    },
    {
        id: 5,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.permissions,
        element: (0, jsx_runtime_1.jsx)(PermissionsPage, {}),
    },
    {
        id: 6,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.archiveTenant,
        element: (0, jsx_runtime_1.jsx)(ArchiveTenants, {}),
    },
    {
        id: 7,
        path: routes_1.ROUTES.ADMIN_ROUTES_NAMES.archivedUsers,
        element: (0, jsx_runtime_1.jsx)(ArchivedUsers, {}),
    },
];
