"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentUserSlice = void 0;
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const user_1 = require("@services/user");
const constants_1 = require("@appConstants/constants");
const initialState = {
    users: [],
    basicUsers: [],
    getUserStatus: constants_1.REQUEST_STATUS.PENDING,
    archivedUsers: [],
};
exports.currentUserSlice = (0, toolkit_1.createSlice)({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(user_1.fetchBasicUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.fetchBasicUsers.fulfilled, (state, action) => {
            if (!action.payload || !Array.isArray(action.payload)) {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                return;
            }
            state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.basicUsers = action.payload;
        })
            .addCase(user_1.fetchBasicUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
        });
        builder
            .addCase(user_1.fetchArchivedUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.fetchArchivedUsers.fulfilled, (state, action) => {
            if (action.payload) {
                state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.archivedUsers = action.payload;
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the archived users', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.fetchArchivedUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the archived users', {
                type: 'error',
            });
        });
        builder
            .addCase(user_1.fetchUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.fetchUsers.fulfilled, (state, action) => {
            if (action.payload) {
                state.users = action.payload;
                state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the users', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.fetchUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the users', {
                type: 'error',
            });
        });
        builder
            .addCase(user_1.deleteUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.deleteUsers.fulfilled, (state, action) => {
            if (action.payload) {
                state.users = [];
                state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Delete user successfully', {
                    type: 'success',
                });
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error deleting the user', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.deleteUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error deleting the user', {
                type: 'error',
            });
        });
        builder
            .addCase(user_1.createUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.createUsers.fulfilled, (state, action) => {
            if (action.payload) {
                state.users = [];
                state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('create user successfully', {
                    type: 'success',
                });
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error create the user', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.createUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error create the user', {
                type: 'error',
            });
        });
        builder
            .addCase(user_1.updateUsers.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.updateUsers.fulfilled, (state, action) => {
            if (action.payload) {
                state.users = [];
                state.getUserStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('update user successfully', {
                    type: 'success',
                });
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error Update the user', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.updateUsers.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error Update the user', {
                type: 'error',
            });
        });
        builder
            .addCase(user_1.restoreUser.pending, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(user_1.restoreUser.fulfilled, (state, action) => {
            if (action.payload) {
                (0, react_toastify_1.toast)('User restored successfully!', {
                    type: 'success',
                });
            }
            else {
                state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Failed to restore the user', {
                    type: 'error',
                });
            }
        })
            .addCase(user_1.restoreUser.rejected, (state) => {
            state.getUserStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Failed to restore the user', {
                type: 'error',
            });
        });
    },
});
_a = exports.currentUserSlice.actions;
exports.default = exports.currentUserSlice.reducer;
