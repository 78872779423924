"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_STEP3 = exports.PAYMENT_STEP2 = exports.PAYMENT_STEP1 = exports.TENANT_PROFILE_INITIAL_VALUES = exports.TENANT_INITIAL_VALUES = void 0;
exports.TENANT_INITIAL_VALUES = {
    tenantName: '',
    practiveName: '',
    primaryContactPhone: '',
    primaryContactEmail: '',
    voipProviderId: '',
    smsNumber: '',
    smsUser: '',
    officeExtensions: '',
    timeZone: '',
    isPaymentEnable: false,
    paymentPercentage: 2,
};
exports.TENANT_PROFILE_INITIAL_VALUES = {
    tenantName: '',
    practiveName: '',
    primaryContactPhone: '',
    primaryContactEmail: '',
    timeZone: '',
};
exports.PAYMENT_STEP1 = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    ownerTitle: '',
    address: '',
    zipCode: '',
    city: '',
    countryCode: '',
};
exports.PAYMENT_STEP2 = {
    salesCode: '',
    dbaName: '',
    legalBusinessName: '',
    taxFilingName: '',
    taxFilingMethod: '',
    address1: '',
    city1: '',
    stateCode1: '',
    zipCode1: '',
    countryCode1: '',
    address2: '',
    city2: '',
    stateCode2: '',
    zipCode2: '',
    countryCode2: '',
};
exports.PAYMENT_STEP3 = {
    bankAcctNum1: '',
    bankRoutingNum1: '',
    bankAcctTypeCd1: '',
    bankName1: '',
    bankAcctNum2: '',
    bankRoutingNum2: '',
    bankAcctTypeCd2: '',
    bankName2: '',
};
