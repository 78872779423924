"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPatients = exports.patientsSlice = void 0;
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("@appConstants/constants");
const patients_1 = require("@services/patients");
const initialState = {
    patients: [],
    getPatientStatus: constants_1.REQUEST_STATUS.IDLE,
};
const getUniquePatients = (patients) => {
    const seen = new Set();
    return patients.filter((patient) => {
        const duplicate = seen.has(patient.PatientId);
        seen.add(patient.PatientId);
        return !duplicate;
    });
};
exports.patientsSlice = (0, toolkit_1.createSlice)({
    name: 'patients',
    initialState,
    reducers: {
        setPatients: (state, action) => {
            state.patients = action.payload;
            state.getPatientStatus = constants_1.REQUEST_STATUS.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(patients_1.fetchPatients.pending, (state) => {
            state.getPatientStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(patients_1.fetchPatients.fulfilled, (state, action) => {
            if (!action.payload || !Array.isArray(action.payload)) {
                state.getPatientStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the patients', {
                    type: 'error',
                });
                return;
            }
            state.getPatientStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.patients = getUniquePatients(action.payload);
        })
            .addCase(patients_1.fetchPatients.rejected, (state, action) => {
            state.getPatientStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the patients', {
                type: 'error',
            });
        });
    },
});
exports.setPatients = exports.patientsSlice.actions.setPatients;
exports.default = exports.patientsSlice.reducer;
