"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
require("./paymentMethod.scss");
const PaymentMethodSelection = ({ handleNext }) => {
    const [selectedMethod, setSelectedMethod] = (0, react_1.useState)('');
    const handlePaymentChange = (event) => {
        setSelectedMethod(event.target.value);
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'paymentMethod', children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'h4', className: 'title', children: "Choose Payment Method" }), (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'body1', children: "Please select a payment method to continue:" }), (0, jsx_runtime_1.jsxs)(material_1.RadioGroup, { value: selectedMethod, onChange: handlePaymentChange, children: [(0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: 'card', control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: 'Credit/Debit Card' }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: 'terminal', control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: 'Terminal Payment' })] }), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: handleNext, disabled: !selectedMethod, children: "Continue" })] }));
};
exports.default = PaymentMethodSelection;
